import {
  Button,
  Table,
  Select,
  message,
  Modal,
  Cascader,
  Card,
  Image,
  Tooltip,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import Hls from "hls.js"
import { useHistory } from "react-router-dom"
import styles from "./Index.module.css"
import * as lossmanageServer from "../../services/lossmanage"
import { QuestionCircleOutlined } from "@ant-design/icons"
import PubInput from "../../../components/PubInput"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"
const { Option } = Select
//开始时间
let ST = HandDefaultTime?.startDate
// 结束时间
let ET = HandDefaultTime?.endDate
// 总时间
let AT = HandDefaultTime?.TotalTime

const FORMAT = "YYYY-MM-DD"
//////////////////////////
// 播放器实例
var player = null
window.__SHOW_LOG__ = true

// Promise封装实例释放
function playerDestroyPromise() {
  return new Promise((resolve, reject) => {
    if (!player) {
      resolve("OK")
    }
    player.stop()
    player
      .destroy()
      .then(() => {
        resolve("OK")
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// 同步方式等待释放
async function playerDestroy() {
  try {
    await playerDestroyPromise()
    player = null
  } catch (e) {
    console.error(e)
  }
}

//////////////////////////

function Index(props) {
  let history = useHistory()
  const myRef = useRef(null)
  var hls = new Hls()
  //shopId：门店id：门店id,staffName:员工,type:1改价，2称重,specific_time:时间
  let JumpToHereInfo = props.location.state
  //从其他页面跳转来带时间的话做处理  -----
  let JST = "" // 开始
  let JET = "" // 结束
  let JAT = "" // 所有
  //日
  if (JumpToHereInfo?.tabKey == 1) {
    //跳转开始时间
    // JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    //跳转结束时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("day").format(FORMAT) +
        " " +
        "00:00:00"
      : ST
    ET = JST
      ? dayjs(JumpToHereInfo?.specific_time).endOf("day").format(FORMAT) +
        " " +
        "23:59:59"
      : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("day"),
      dayjs(JumpToHereInfo?.specific_time).endOf("day"),
    ]
  } else if (JumpToHereInfo?.tabKey == 2) {
    //周
    const NewTime = JumpToHereInfo?.specific_time[1].split("-")
    JST = NewTime ? NewTime[0] : ""
    //跳转结束时间
    JET = NewTime ? NewTime[1] : ""
    ST = JST ? dayjs(JST).format("YYYY-MM-DD") + " " + "00:00:00" : ST
    ET = JET ? dayjs(JET).format("YYYY-MM-DD") + " " + "23:59:59" : ET
    JAT = [dayjs(JST), dayjs(JET)]
  } else if (JumpToHereInfo?.tabKey == 3) {
    //月
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("month").format(FORMAT) +
        " " +
        "00:00:00"
      : ST
    ET = JST
      ? dayjs(JumpToHereInfo?.specific_time).endOf("month").format(FORMAT) +
        " " +
        "23:59:59"
      : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("month"),
      dayjs(JumpToHereInfo?.specific_time).endOf("month"),
    ]
  } else {
    JAT = AT
  }
  //--------------------------
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [addressOptions, setAddressOptions] = useState([]) //省市区树
  const [total, setTotal] = useState() //总数
  const [loading, setLoading] = useState(false)
  //
  const [seeDetale, setSeeDetale] = useState(false) // 查看详情
  const [seeDetaleInfo, setSeeDetaleInfo] = useState({}) // 查看详情内容
  const [videlUrl, setVidelUrl] = useState("") // 视频链接
  //
  const [typeNum, setTypeNum] = useState("")
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [GiveParams, setGiveParams] = useState({
    timeArr: JAT,
    startTime: ST,
    endTime: ET,
    pageNum: 1,
    pageSize: 20,
    shopId: Number(JumpToHereInfo?.shopId) || undefined,
    deviceSn: undefined,
    opeator: JumpToHereInfo?.staffName || undefined, //操作人员
    type: JumpToHereInfo?.type || undefined, //异常类型
    code: undefined, // //商品名称/商品skuCode
    ProvinceCityRegion: [], //province省,city市,region区
    // ids: [],
  })

  const [ImgUrl, setImgUrl] = useState("") //图片链接

  const [isModalOpen, setIsModalOpen] = useState(false)

  /**
   * 判断时间区间为31天
   */
  const iSTimeMaxDay = (start, end) => {
    const dayNum = dayjs(end).diff(dayjs(start), "day")
    if (dayNum > 31) {
      message.error("时间范围不能超过31天")
      return false
    } else {
      return true
    }
  }
  /**
   * 导出记录
   */
  const [isExportRecord, setIsExportRecord] = useState(false) // 导出记录Modal状态
  const [loading1, setLoading1] = useState(false)
  const [total1, setTotal1] = useState(0) //总数
  const [exportList, setExportList] = useState([])
  const [GiveExportParams, setGiveExportParams] = useState({
    pageNum: 1,
    pageSize: 10,
    exportTypes: [1],
    status: undefined,
    startDate: HandDefaultTime?.startDate,
    endDate: HandDefaultTime?.endDate,
    TotalTiem: HandDefaultTime?.TotalTime,
  })
  //保证每次拿到的都是准确的值
  const updataExportRef = useRef({
    GiveExportParamsRef: {},
  })

  useEffect(() => {
    updataExportRef.current = {
      GiveExportParamsRef: GiveExportParams,
    }
  }, [GiveExportParams])
  //
  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //调用获取表格列表数据
    setTimeout(getGoodsWeighDetail, 0)
    //调用门店设备
    getShopTreeList()
    //省市区树
    getAreaTree()
  }, [])

  /**
   * 省市区树
   */
  const getAreaTree = async () => {
    try {
      const { code, data, msg } = await lossmanageServer.Area_Tree()
      if (code != 200) {
        message.error(msg)
        return
      }
      setAddressOptions(data)
    } catch (error) {
      message.error(error)
      return
    }
  }

  // 销毁视cavas
  useEffect(() => {
    if (!isModalOpen) {
      // 销毁视cavas
      return playerDestroy
    }
  }, [!isModalOpen])

  /**
   * 动态获取高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  //门店设备
  const getShopTreeList = async (value) => {
    try {
      const params = {
        code: value,
        districtId: "",
      }
      const res = await lossmanageServer.ShopTreeList(params)
      if (!res) {
        return false
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.value = v.shopId
          v.label = v.name
          if (v.deviceList) {
            v.deviceList.map((w) => {
              w.value = w.deviceSn
              w.label = w.cdKeyCode
            })
          }
          v.children = v.deviceList
        })
        // 合并原始数组与查询出的数组，并去重
        const NewArr = data.concat(shopList)
        const obj = {}
        let NewArr1 = []
        for (let i = 0; i < NewArr.length; i++) {
          if (!obj[NewArr[i]["shopId"]]) {
            obj[NewArr[i]["shopId"]] = 1
            NewArr1.push(NewArr[i])
          }
        }
        setShopList(NewArr1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const params = {
        ...GiveParamsRef,
        province: GiveParamsRef.ProvinceCityRegion[0] || "",
        city: GiveParamsRef.ProvinceCityRegion[1] || "",
        region: GiveParamsRef.ProvinceCityRegion[2] || "",
        timeArr: [],
      }
      const { code, msg, data } = await lossmanageServer.WarningLog(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  /**
   * 导出任务列表
   */
  const getExportWorkList = async () => {
    try {
      const { GiveExportParamsRef } = updataExportRef.current
      setLoading1(true)
      const params = {
        ...GiveExportParamsRef,
        TotalTiem: "",
      }
      const { code, msg, data } = await lossmanageServer.exportWorkList(params)
      if (code != 200) {
        setLoading1(false)
        message.error(msg)
        return
      }
      setLoading1(false)
      setTotal1(data.total) //数据总条数
      setExportList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading1(false)
    }
  }

  //导出
  const getExport = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      const params = {
        ...GiveParamsRef,
        province: GiveParamsRef.ProvinceCityRegion[0] || "",
        city: GiveParamsRef.ProvinceCityRegion[1] || "",
        region: GiveParamsRef.ProvinceCityRegion[2] || "",
        timeArr: [],
      }
      const res = await lossmanageServer.ExportWarningLog(params)
      if (!res) {
        return false
      }
      const { code, msg, data } = res
      if (code != 200) {
        message.error(msg)
      }
      console.log(data)
      if (data) {
        message.success("导出成功")
        setIsExportRecord(true)
        setGiveExportParams({
          pageNum: 1,
          pageSize: 10,
          exportTypes: [1],
          status: undefined,
          startDate: dayjs().format("YYYY-MM-DD") + " " + "00:00:00",
          endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
          TotalTiem: [
            dayjs(dayjs().format("YYYY-MM-DD") + " " + "00:00:00"),
            dayjs(dayjs().format("YYYY-MM-DD") + " " + "23:59:59"),
          ],
        })

        //调取导出任务列表接口
        setTimeout(getExportWorkList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
          // ids: [],
        })
        // setSelectedRowKeys([])
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  //分页1
  const onTableChange1 = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total1}条`,
      pageSize: GiveExportParams.pageSize,
      current: GiveExportParams.pageNum,
      total: total1,
      onChange: (current) => {
        setGiveExportParams({
          ...GiveExportParams,
          pageNum: current,
        })
        setTimeout(getExportWorkList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 监控回放
   */
  const getPlaybackCamera = async (params) => {
    try {
      console.log(params)
      const { code, data, msg } = await lossmanageServer.PlaybackCamera(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setVidelUrl(data?.playUrl || "")
      setTypeNum(data.type)
      //  海康
      if (data && data.playUrl && data.type == 1) {
        console.log(data)
        if (Hls.isSupported()) {
          hls.loadSource(data.playUrl)
          // hls.loadSource(
          //   "https://hw-m-l.cztv.com/channels/lantian/channel002/1080p.m3u8"
          // )
          hls.attachMedia(myRef.current)
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            myRef.current.play()
          })
        } else {
          message.error("不支持HLS播放器")
        }
      } else if (data && data.playUrl && data.type == 2) {
        // TP-LINK商用云平台
        var TumsPlayer = window["tums-player"].default
        player = new TumsPlayer("video-container", {
          type: "relay", // 协议类型，rtsp/relay
          url: data.playUrl, // 取流地址
          pluginPath: "https://smbcloud.tp-link.com.cn/static",
          streamType: "sdvod",
          autoplay: true,
          eventType: [1, 2],
          startTime: params.startTime, // 跳转到指定时间开始回放
          endTime: params.endTime,
          userId: data.userId,
        })
      } else {
        //异常
        message.error("异常")
      }
    } catch (error) {
      console.log(error)
    }
  }

  //表格复选框
  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys)
  //   setGiveParams({
  //     ...GiveParams,
  //     ids: newSelectedRowKeys,
  //   })
  // }
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // }
  //跳转
  const handJump = (info) => {
    // type==1:监控改价出售
    // type==2:异常称重
    info.staffName = info.opeator
    let path =
      info.type == 1
        ? "/admin/lossmanage/updateroad"
        : "/admin/datareport/weightrecord"
    return (
      <>
        <a
          type="link"
          onClick={() => {
            let NewInfo = JSON.parse(JSON.stringify(info))
            if (NewInfo.allOutput && typeof info.allOutput !== "object") {
              NewInfo.allOutput = JSON.parse(NewInfo.allOutput)
              // 选择商品
              NewInfo?.allOutput.unshift({
                price: NewInfo.price || 0,
                sku_code: NewInfo.skuCode || "",
                name: NewInfo.skuName || "",
                saleWeight: NewInfo.saleWeight || "",
              })
            }
            //
            /**
             * 重量/数据 ， 商品总价
             */
            if (NewInfo && NewInfo?.allOutput) {
              NewInfo?.allOutput.map((v) => {
                v.saleWeight =
                  (info.skuUnit == 0
                    ? info.saleWeight == 0
                      ? 0
                      : (info.saleWeight / 1000).toFixed(2)
                    : info?.saleWeight) +
                  "" +
                  (info.skuUnit == 1 ? "个" : info.skuUnit == 0 ? "kg" : "")
                // 商品总价
                v.totalPrice =
                  (info.skuUnit == 0
                    ? NewInfo?.saleWeight / 1000
                    : NewInfo?.saleWeight) * v.price
              })
            }
            //
            let NewSetData = []
            if (NewInfo && NewInfo?.allOutput) {
              if (NewInfo?.allOutput.length > 4) {
                NewInfo?.allOutput.map((v, i) => {
                  if (i == 0 || i == 1 || i == 2 || i == 3) {
                    NewSetData.push(v)
                  }
                })
                NewInfo.allOutput = NewSetData
              }
              if (NewInfo?.allOutput.length == 1) {
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
              }
              if (NewInfo?.allOutput.length == 2) {
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
              }
              if (NewInfo?.allOutput.length == 3) {
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
              }
            }

            console.log(NewInfo)
            setSeeDetaleInfo(NewInfo)
            setSeeDetale(true)
          }}
        >
          查看详情
        </a>

        <a
          type="link"
          style={{ marginLeft: "20px" }}
          onClick={() => {
            setTypeNum(2)
            let NewInfo = JSON.parse(JSON.stringify(info))
            if (NewInfo.allOutput && typeof NewInfo.allOutput !== "object") {
              NewInfo.allOutput = JSON.parse(NewInfo.allOutput)
              // 选择商品
              NewInfo?.allOutput.unshift({
                price: NewInfo.price || 0,
                sku_code: NewInfo.skuCode || "",
                name: NewInfo.skuName || "",
                saleWeight: NewInfo.saleWeight || "",
              })
            }
            /**
             * 重量/数据 ， 商品总价
             */
            if (NewInfo && NewInfo?.allOutput) {
              NewInfo?.allOutput.map((v) => {
                v.saleWeight =
                  (NewInfo.skuUnit == 0
                    ? NewInfo.saleWeight == 0
                      ? 0
                      : (NewInfo.saleWeight / 1000).toFixed(2)
                    : NewInfo?.saleWeight) +
                  "" +
                  (NewInfo.skuUnit == 1
                    ? "个"
                    : NewInfo.skuUnit == 0
                    ? "kg"
                    : "")
                v.totalPrice = v.totalPrice =
                  (NewInfo.skuUnit == 0
                    ? NewInfo?.saleWeight / 1000
                    : NewInfo?.saleWeight) * v.price
              })
            }

            //
            let NewSetData = []
            if (NewInfo && NewInfo?.allOutput) {
              if (NewInfo?.allOutput.length > 4) {
                NewInfo?.allOutput.map((v, i) => {
                  if (i == 0 || i == 1 || i == 2 || i == 3) {
                    NewSetData.push(v)
                  }
                })
                NewInfo.allOutput = NewSetData
              }
              if (NewInfo?.allOutput.length == 1) {
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
              }
              if (NewInfo?.allOutput.length == 2) {
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
              }
              if (NewInfo?.allOutput.length == 3) {
                NewInfo.allOutput.push({
                  price: "",
                  sku_code: "-",
                  name: "-",
                  saleWeight: "",
                })
              }
            }
            //
            let NowDate = dayjs(dayjs(NewInfo.triggerTime))
            let newStart = NowDate.subtract(1, "minute").toDate().valueOf()
            let newEnd = NowDate.add(1, "minute").toDate().valueOf()
            const params = {
              startTime: newStart,
              endTime: newEnd,
              deviceSn: NewInfo?.deviceSn || "",
            }

            setIsModalOpen(true)
            setTimeout(() => {
              getPlaybackCamera(params)
            }, 100)
            setSeeDetaleInfo(NewInfo)
          }}
        >
          查看录像
        </a>
      </>
    )
  }

  //查询
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    if (!iSTimeMaxDay(GiveParams?.startTime, GiveParams?.endTime)) {
      return
    }
    setTimeout(getGoodsWeighDetail, 0)
  }

  //重置
  const handClear = () => {
    // 重置时将时间恢复默认
    // 开始时间
    ST = HandDefaultTime?.startDate

    // 结束时间
    ET = HandDefaultTime?.endDate
    JumpToHereInfo = false
    setGiveParams({
      ...GiveParams,
      timeArr: AT,
      startTime: ST,
      endTime: ET,
      pageNum: 1,
      deviceSn: undefined,
      opeator: undefined,
      type: undefined,
      shopId: undefined,
      code: undefined,
      ProvinceCityRegion: [], //province省,city市,region区
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  const columns = [
    {
      title: "异常触发时间",
      dataIndex: "triggerTime",
      ellipsis: { showTitle: true },
      key: "triggerTime",
      width: 150,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 150,
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },

      key: "shopCode",
      width: 200,
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 120,
    },
    {
      title: "激活码",
      dataIndex: "cdKeyCode",
      ellipsis: { showTitle: true },
      key: "cdKeyCode",
      width: 120,
    },
    {
      title: "员工工号",
      dataIndex: "opeator",
      ellipsis: { showTitle: true },
      key: "opeator",
      width: 120,
    },
    {
      title: "防损规则名称",
      dataIndex: "ruleName",
      ellipsis: { showTitle: true },
      key: "ruleName",
      width: 150,
    },
    {
      title: "防损规则编号",
      dataIndex: "ruleId",
      ellipsis: { showTitle: true },
      key: "ruleId",
      width: 150,
    },
    {
      title: "异常类型",
      dataIndex: "type",
      ellipsis: { showTitle: true },
      key: "type",
      width: 120,
      render: (info) => {
        return (
          <>
            {info == 1
              ? "监控改价出售"
              : info == 2
              ? "AI监控：商品被低价出售 "
              : info == 3
              ? "监控指定商品被点选"
              : info == 4
              ? "监控不点选首位推荐"
              : ""}
          </>
        )
      },
    },
    {
      title: "异常原因",
      dataIndex: "msg",
      ellipsis: { showTitle: true },
      key: "msg",
      width: 120,
    },
    {
      title: "识别操作类型",
      dataIndex: "chooseResultType",
      ellipsis: { showTitle: true },
      key: "chooseResultType",
      width: 120,
      render: (info) => {
        return (
          <div>
            {info == 0
              ? "无后续操作"
              : info == 1
              ? "自动打印"
              : info == 2
              ? "识别结果点选"
              : info == 3
              ? "快捷键点选"
              : info == 4
              ? "搜索中选择"
              : info == 5
              ? "重新识别"
              : info == 6
              ? "重新识别"
              : info == 8
              ? "直打标签"
              : info == 9
              ? "学习模式"
              : info == 10
              ? "临时商品"
              : ""}
          </div>
        )
      },
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },
      key: "skuCode",
      width: 120,
    },
    {
      title: "选择商品",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },
      key: "skuName",
      width: 120,
    },
    {
      title: "识别商品",
      dataIndex: "allOutput",
      ellipsis: { showTitle: true },
      key: "allOutput",
      width: 200,
      render: (info, rowInfo) => {
        // if (info && typeof info !== "object") {
        if (info) {
          let NewData
          if (typeof info !== "object") {
            NewData = JSON.parse(info)
          } else {
            NewData = info
          }
          return (
            <div>
              {NewData.map((v, i) => {
                return (
                  <div key={i} className={styles.policeroad_allOutput}>
                    {i == 0 ? (
                      <>
                        {" "}
                        首位： {v.name} ({v.price ? v.price / 100 : 0}元/
                        {rowInfo.skuUnit == 1
                          ? "个"
                          : rowInfo.skuUnit == 0
                          ? "kg"
                          : ""}
                        )
                      </>
                    ) : i == 1 ? (
                      <>
                        {" "}
                        次位： {v.name} ({v.price ? v.price / 100 : 0}元/
                        {rowInfo.skuUnit == 1
                          ? "个"
                          : rowInfo.skuUnit == 0
                          ? "kg"
                          : ""}
                        )
                      </>
                    ) : i == 2 ? (
                      <>
                        {" "}
                        三位： {v.name} ({v.price ? v.price / 100 : 0}元/
                        {rowInfo.skuUnit == 1
                          ? "个"
                          : rowInfo.skuUnit == 0
                          ? "kg"
                          : ""}
                        )
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                )
              })}
            </div>
          )
        }
      },
    },
    {
      title: "称重商品图片",
      dataIndex: "imageUrl",
      key: "imageUrl",
      ellipsis: { showTitle: true },
      width: 200,
      render: (info) => {
        return <Image width={100} src={info} />
      },
    },

    {
      title: "操作",
      ellipsis: { showTitle: true },
      width: 200,
      fixed: "right",
      render: (info) => {
        return handJump(info)
      },
    },
  ]

  const columns1 = [
    {
      title: "操作人",
      dataIndex: "userName",
      ellipsis: { showTitle: true },
      key: "userName",
      width: 150,
    },
    {
      title: "操作时间",
      dataIndex: "createdAt",
      ellipsis: { showTitle: true },
      key: "createdAt",
      width: 150,
    },
    {
      title: "文件生成时间",
      dataIndex: "fileCreateAt",
      ellipsis: { showTitle: true },
      key: "fileCreateAt",
      width: 150,
    },
    {
      title: "文件失效时间",
      dataIndex: "fileLoseAt",
      ellipsis: { showTitle: true },
      key: "fileLoseAt",
      width: 150,
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="文件只保留72小时">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
    },
    {
      title: "文件生成类型",
      dataIndex: "exportType",
      ellipsis: { showTitle: true },
      key: "exportType",
      width: 150,
      render: (info) => {
        return <>{info == 1 ? "异常记录导出" : ""}</>
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: { showTitle: true },
      key: "status",
      width: 150,
      render: (info) => {
        return (
          <div
            style={{
              color:
                info == 1 || info == 2
                  ? "orange"
                  : info == 3
                  ? "#2eb998"
                  : info == 4
                  ? "red"
                  : "",
            }}
          >
            {info == 1
              ? "等待中"
              : info == 2
              ? "处理中"
              : info == 3
              ? "处理完成"
              : info == 4
              ? "处理失败"
              : ""}
          </div>
        )
      },
    },
    {
      title: "操作",
      dataIndex: "fileUrl",
      ellipsis: { showTitle: true },
      key: "fileUrl",
      width: 150,
      render: (info) => {
        return <>{info ? <a href={info}>下载文件</a> : null}</>
      },
    },
    {
      title: "失败消息",
      dataIndex: "message",
      ellipsis: { showTitle: true },
      key: "message",
      width: 150,
    },
  ]
  return (
    <div className={styles.policeroad_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.policeroadbody_policeroad_page_search}>
          <div className={styles.policeroadbody_policeroad_search_body}>
            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>所属区域：</span>
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属区域"
                style={{ width: 200 }}
                fieldNames={{
                  label: "name",
                  value: "name",
                  children: "areaListRspList",
                }}
                options={addressOptions}
                value={GiveParams.ProvinceCityRegion}
                allowClear
                changeOnSelect={true}
                onChange={(e) =>
                  setGiveParams({ ...GiveParams, ProvinceCityRegion: e || "" })
                }
              />
            </div>

            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>门店/设备：</span>
              <Cascader
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                showSearch
                style={{ width: 220 }}
                changeOnSelect
                options={shopList}
                placeholder="请选择门店/设备"
                value={
                  GiveParams?.shopId && GiveParams?.deviceSn
                    ? [GiveParams?.shopId, GiveParams?.deviceSn]
                    : GiveParams?.shopId
                    ? [GiveParams?.shopId]
                    : []
                }
                onSearch={(e) => {
                  if (e.length >= 2) {
                    getShopTreeList(e)
                  }
                }}
                onChange={(e) => {
                  if (e) {
                    setGiveParams({
                      ...GiveParams,
                      shopId: e[0] ? e[0] : "", //商品编号
                      deviceSn: e[1] ? e[1] : "", //操作设备
                    })
                  } else {
                    setGiveParams({
                      ...GiveParams,
                      shopId: undefined, //商品编号
                      deviceSn: undefined, //操作设备
                    })
                  }
                }}
              />
            </div>

            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>员工工号：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, opeator: val })
                }}
                placeholder="员工工号"
                value={GiveParams.opeator}
              />
            </div>

            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>异常触发时间：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startTime: val.startDate,
                    endTime: val.endDate,
                  })
                }}
              />
            </div>

            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>商品名称/PLU：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    code: val,
                  })
                }}
                placeholder="商品名称/PLU"
                value={GiveParams.code}
              />
            </div>

            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>异常类型：</span>
              <Select
                style={{ width: 200 }}
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    type: e,
                  })
                }}
                value={GiveParams.type}
                placeholder="请选择异常类型"
              >
                <Option value="1">监控改价出售</Option>
                <Option value="2">AI监控：商品被低价出售</Option>
                <Option value="3">监控指定商品被点选</Option>
                <Option value="4">监控不点选首位推荐</Option>
              </Select>
            </div>
          </div>
          <div className={styles.policeroadbody_policeroad_search_button}>
            <Button type="primary" onClick={() => handSearch()}>
              查询
            </Button>
            <Button onClick={() => handClear()}>重置</Button>
          </div>
        </div>
      </Card>
      {/* <div className={styles.}policeroad_page_body"> */}
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        {/* 导出 */}
        <Button type="primary" onClick={getExport}>
          导出数据
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setIsExportRecord(true)
            setGiveExportParams({
              pageNum: 1,
              pageSize: 10,
              exportTypes: [1],
              status: undefined,
              startDate: dayjs().format("YYYY-MM-DD") + " " + "00:00:00",
              endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
              TotalTiem: [
                dayjs(dayjs().format("YYYY-MM-DD") + " " + "00:00:00"),
                dayjs(dayjs().format("YYYY-MM-DD") + " " + "23:59:59"),
              ],
            })

            //调取导出任务列表接口
            setTimeout(getExportWorkList, 0)
          }}
          style={{ marginLeft: "10px" }}
        >
          导出记录
        </Button>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          // rowSelection={rowSelection}
          scroll={{ x: "max-content", y: `${WinHeight - 470}px` }}
        />
      </Card>

      <Modal
        keyboard
        title="查看录像"
        open={isModalOpen}
        maskClosable
        footer={[]}
        width={"90%"}
        onCancel={() => {
          setIsModalOpen(false)
          setSeeDetaleInfo({})
        }}
      >
        <div className={styles.policeroad_imgmodal}>
          <div className={styles.policeroad_imgmodal_left}>
            {typeNum == 1 ? (
              <video
                ref={myRef}
                width="100%"
                height="100%"
                muted
                controls
                autoPlay
              ></video>
            ) : typeNum == 2 ? (
              <div
                id="video-container"
                style={{ width: "100%", height: "100%" }}
                onClick={() => {
                  player?.fullscreen()
                }}
              ></div>
            ) : null}
          </div>

          <div className={styles.policeroad_imgmodal_right}>
            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>异常类型</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span style={{ color: "#F56C6C" }}>
                  {seeDetaleInfo?.type == 1
                    ? "监控改价出售"
                    : seeDetaleInfo?.type == 2
                    ? "AI监控：商品被低价出售"
                    : seeDetaleInfo?.type == 3
                    ? "监控指定商品被点选"
                    : seeDetaleInfo?.type == 4
                    ? "监控不点选首位推荐"
                    : "" || ""}
                </span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>异常原因</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span style={{ color: "#F56C6C" }}>
                  {seeDetaleInfo?.msg || ""}
                </span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>时间</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.triggerTime || ""}</span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>门店</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.shopName || ""}</span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>秤号</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.scaleNo || ""}</span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>员工工号</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.opeator || ""}</span>
              </div>
            </div>

            {/* 分割区域 */}
            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_fenge}>
                {seeDetaleInfo?.type == 1 ? (
                  <span>监控改价出售详情</span>
                ) : seeDetaleInfo?.type == 2 ? (
                  <span>AI监控：商品被低价出售详情</span>
                ) : seeDetaleInfo?.type == 3 ? (
                  <span>监控指定商品被点选详情</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* 区分内容 */}
            {/* 监控改价出售 */}

            {seeDetaleInfo?.type == 1 ? (
              <>
                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品名称</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuName || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品PLU</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuCode || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>重量/数量</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>
                      {(seeDetaleInfo?.skuUnit == 0
                        ? seeDetaleInfo?.saleWeight
                          ? (seeDetaleInfo?.saleWeight / 1000).toFixed(2)
                          : 0
                        : seeDetaleInfo?.saleWeight) +
                        "" +
                        (seeDetaleInfo?.skuUnit == 1
                          ? "个"
                          : seeDetaleInfo?.skuUnit == 0
                          ? "kg"
                          : "") || "0"}
                    </span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价前单价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.price / 100 || 0}元</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价后单价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.salePrice / 100 || 0}元</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价前总价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.amount / 100 || 0}元</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价后总价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.saleAmount / 100 || 0}元</span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* AI监控：商品被低价出售 */}
            {seeDetaleInfo?.type == 2 || seeDetaleInfo?.type == 4 ? (
              <>
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>类型</div>
                  <div className={styles.policeroad_sbtitle_two}>选择商品</div>
                  <div className={styles.policeroad_sbtitle_two}>
                    首位识别商品
                  </div>
                  <div className={styles.policeroad_sbtitle_two}>
                    次位识别商品
                  </div>
                  <div className={styles.policeroad_sbtitle_two}>
                    三位识别商品
                  </div>
                </div>
                {/* 商品名称 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品名称</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip title={v.name}>
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.name}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 商品PLU */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品PLU</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip title={v.sku_code}>
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.sku_code}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 商品单价 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品单价</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip
                        title={
                          v.price
                            ? (v.price / 100).toFixed(2) +
                              "元" +
                              (seeDetaleInfo?.skuUnit == 1
                                ? "个"
                                : seeDetaleInfo?.skuUnit == 0
                                ? "kg"
                                : "")
                            : "-"
                        }
                      >
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.price
                            ? (v.price / 100).toFixed(2) +
                              "元/" +
                              (seeDetaleInfo?.skuUnit == 1
                                ? "个"
                                : seeDetaleInfo?.skuUnit == 0
                                ? "kg"
                                : "")
                            : "-"}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 重量/数量 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>重量/数量</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip
                        title={v.saleWeight && v.name ? v.saleWeight : "-"}
                      >
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.saleWeight && v.name && v.name !== "-"
                            ? v.saleWeight
                            : "-"}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 商品总价 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品总价</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip
                        title={
                          v.totalPrice
                            ? (v.totalPrice / 100).toFixed(2) + "元"
                            : "-"
                        }
                      >
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.totalPrice
                            ? (v.totalPrice / 100).toFixed(2) + "元"
                            : "-"}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
              </>
            ) : (
              ""
            )}
            {/* 监控指定商品被点选 */}

            {seeDetaleInfo?.type == 3 ? (
              <>
                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品名称</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuName || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品PLU</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuCode || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>重量/数量</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>
                      {(seeDetaleInfo?.skuUnit == 0
                        ? seeDetaleInfo?.saleWeight
                          ? (seeDetaleInfo?.saleWeight / 1000).toFixed(2)
                          : 0
                        : seeDetaleInfo?.saleWeight) +
                        "" +
                        (seeDetaleInfo?.skuUnit == 1
                          ? "个"
                          : seeDetaleInfo?.skuUnit == 0
                          ? "kg"
                          : "") || "0"}
                    </span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品单价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>
                      {seeDetaleInfo?.salePrice / 100 || 0}元/
                      {seeDetaleInfo?.skuUnit == 1
                        ? "个"
                        : seeDetaleInfo?.skuUnit == 0
                        ? "kg"
                        : ""}
                    </span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品总价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.saleAmount / 100 || 0}元</span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>

      {/* 查看详情 */}
      <Modal
        keyboard
        title="查看详情"
        open={seeDetale}
        maskClosable
        width={"70%"}
        footer={[]}
        onCancel={() => {
          setSeeDetale(false)
          setSeeDetaleInfo({})
        }}
      >
        <div className={styles.policeroad_imgmodal}>
          <div className={styles.policeroad_imgmodal_left}>
            <div className={styles.policeroad_imgmodal_leftsb}>
              <Image width={"100%"} src={seeDetaleInfo?.imageUrl || ""} />
            </div>
            <div>
              <Button
                type="link"
                onClick={() => {
                  var x = new XMLHttpRequest()
                  x.open("GET", seeDetaleInfo?.imageUrl, true)
                  x.responseType = "blob"
                  x.onload = function (e) {
                    var url = window.URL.createObjectURL(x.response)
                    var a = document.createElement("a")
                    a.href = url
                    a.download = ""
                    a.click()
                  }
                  x.send()
                }}
              >
                下载图片
              </Button>
            </div>
          </div>

          <div className={styles.policeroad_imgmodal_right}>
            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>异常类型</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span style={{ color: "#F56C6C" }}>
                  {seeDetaleInfo?.type == 1
                    ? "监控改价出售"
                    : seeDetaleInfo?.type == 2
                    ? "AI监控：商品被低价出售"
                    : seeDetaleInfo?.type == 3
                    ? "监控指定商品被点选"
                    : seeDetaleInfo?.type == 4
                    ? "监控不点选首位推荐"
                    : "" || ""}
                </span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>异常原因</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span style={{ color: "#F56C6C" }}>
                  {seeDetaleInfo?.msg || ""}
                </span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>时间</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.triggerTime || ""}</span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>门店</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.shopName || ""}</span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>秤号</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.scaleNo || ""}</span>
              </div>
            </div>

            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_listleft}>
                <span>员工工号</span>
              </div>
              <div className={styles.policeroad_imgmodal_right_listright}>
                <span>{seeDetaleInfo?.opeator || ""}</span>
              </div>
            </div>

            {/* 分割区域 */}
            <div className={styles.policeroad_imgmodal_right_list}>
              <div className={styles.policeroad_imgmodal_right_fenge}>
                {seeDetaleInfo?.type == 1 ? (
                  <span>监控改价出售详情</span>
                ) : seeDetaleInfo?.type == 2 ? (
                  <span>AI监控：商品被低价出售详情</span>
                ) : seeDetaleInfo?.type == 3 ? (
                  <span>监控指定商品被点选详情</span>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* 区分内容 */}
            {/* 监控改价出售 */}
            {seeDetaleInfo?.type == 1 ? (
              <>
                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品名称</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuName || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品PLU</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuCode || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>重量/数量</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>
                      {(seeDetaleInfo?.skuUnit == 0
                        ? seeDetaleInfo?.saleWeight
                          ? (seeDetaleInfo?.saleWeight / 1000).toFixed(2)
                          : 0
                        : seeDetaleInfo?.saleWeight) +
                        "" +
                        (seeDetaleInfo?.skuUnit == 1
                          ? "个"
                          : seeDetaleInfo?.skuUnit == 0
                          ? "kg"
                          : "") || "0"}
                    </span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价前单价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.price / 100 || 0}元</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价后单价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.salePrice / 100 || 0}元</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价前总价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.amount / 100 || 0}元</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>改价后总价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.saleAmount / 100 || 0}元</span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* AI监控：商品被低价出售 */}
            {seeDetaleInfo?.type == 2 || seeDetaleInfo?.type == 4 ? (
              <>
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>类型</div>
                  <div className={styles.policeroad_sbtitle_two}>选择商品</div>
                  <div className={styles.policeroad_sbtitle_two}>
                    首位识别商品
                  </div>
                  <div className={styles.policeroad_sbtitle_two}>
                    次位识别商品
                  </div>
                  <div className={styles.policeroad_sbtitle_two}>
                    三位识别商品
                  </div>
                </div>
                {/* 商品名称 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品名称</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip title={v.name}>
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.name}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 商品PLU */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品PLU</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip title={v.sku_code}>
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.sku_code}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 商品单价 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品单价</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip
                        title={
                          v.price
                            ? (v.price / 100).toFixed(2) +
                              "元" +
                              (seeDetaleInfo?.skuUnit == 1
                                ? "个"
                                : seeDetaleInfo?.skuUnit == 0
                                ? "kg"
                                : "")
                            : "-"
                        }
                      >
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.price
                            ? (v.price / 100).toFixed(2) +
                              "元/" +
                              (seeDetaleInfo?.skuUnit == 1
                                ? "个"
                                : seeDetaleInfo?.skuUnit == 0
                                ? "kg"
                                : "")
                            : "-"}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 重量/数量 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>重量/数量</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip
                        title={v.saleWeight && v.name ? v.saleWeight : "-"}
                      >
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.saleWeight && v.name && v.name !== "-"
                            ? v.saleWeight
                            : "-"}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
                {/* 商品总价 */}
                <div className={styles.policeroad_sbtitle}>
                  <div className={styles.policeroad_sbtitle_one}>商品总价</div>
                  {seeDetaleInfo?.allOutput.map((v, i) => {
                    return (
                      <Tooltip
                        title={
                          v.totalPrice
                            ? (v.totalPrice / 100).toFixed(2) + "元"
                            : "-"
                        }
                      >
                        <div className={styles.policeroad_sbtitle_two} key={i}>
                          {v.totalPrice
                            ? (v.totalPrice / 100).toFixed(2) + "元"
                            : "-"}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>
              </>
            ) : (
              ""
            )}

            {/* 监控指定商品被点选 */}
            {seeDetaleInfo?.type == 3 ? (
              <>
                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品名称</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuName || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品PLU</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.skuCode || ""}</span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>重量/数量</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>
                      {(seeDetaleInfo?.skuUnit == 0
                        ? seeDetaleInfo?.saleWeight
                          ? (seeDetaleInfo?.saleWeight / 1000).toFixed(2)
                          : 0
                        : seeDetaleInfo?.saleWeight) +
                        "" +
                        (seeDetaleInfo?.skuUnit == 1
                          ? "个"
                          : seeDetaleInfo?.skuUnit == 0
                          ? "kg"
                          : "") || "0"}
                    </span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品单价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>
                      {seeDetaleInfo?.salePrice / 100 || 0}元/
                      {seeDetaleInfo?.skuUnit == 1
                        ? "个"
                        : seeDetaleInfo?.skuUnit == 0
                        ? "kg"
                        : ""}
                    </span>
                  </div>
                </div>

                <div className={styles.policeroad_imgmodal_right_list}>
                  <div className={styles.policeroad_imgmodal_right_listleft}>
                    <span>商品总价</span>
                  </div>
                  <div className={styles.policeroad_imgmodal_right_listright}>
                    <span>{seeDetaleInfo?.saleAmount / 100 || 0}元</span>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>

      {/* 导出记录 */}
      <Modal
        keyboard
        title="导出记录"
        open={isExportRecord}
        maskClosable
        width={"70%"}
        footer={[]}
        onCancel={() => {
          setIsExportRecord(false)
        }}
      >
        <div className={styles.policeroadbody_policeroad_page_search}>
          <div className={styles.policeroadbody_policeroad_search_body}>
            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>导出时间：</span>
              <PubRangePicker
                value={GiveExportParams.TotalTiem}
                onChange={(val) => {
                  setGiveExportParams({
                    ...GiveExportParams,
                    TotalTiem: val.TotalTime,
                    startDate: val.startDate,
                    endDate: val.endDate,
                  })
                }}
              />

              {/* <RangePicker
                locale={locale}
                value={
                  GiveExportParams.TotalTiem.length == 0
                    ? []
                    : [
                        dayjs(GiveExportParams.TotalTiem[0], dateFormat),
                        dayjs(GiveExportParams.TotalTiem[1], dateFormat),
                      ]
                }
                onChange={(e) => {
                  if (e) {
                    setGiveExportParams({
                      ...GiveExportParams,
                      TotalTiem: e,
                      startDate: e[0].format("YYYY-MM-DD") + " " + "00:00:00",
                      endDate: e[1].format("YYYY-MM-DD") + " " + "23:59:59",
                    })
                    //清空员工下拉框选中数据
                  } else {
                    setGiveExportParams({
                      ...GiveExportParams,
                      TotalTiem: AT,
                      startDate:
                        dayjs().format("YYYY-MM-DD") + " " + "00:00:00",
                      endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
                    })
                  }
                }}
              /> */}
            </div>
            <div className={styles.policeroadbody_policeroad_search_body_child}>
              <span>状态：</span>
              <Select
                style={{ width: 200 }}
                allowClear
                onChange={(e) => {
                  setGiveExportParams({
                    ...GiveExportParams,
                    status: e,
                  })
                }}
                value={GiveExportParams.status}
                placeholder="请选择状态"
              >
                <Option value="1">等待中</Option>
                <Option value="2">处理中</Option>
                <Option value="3">处理完成</Option>
                <Option value="4">处理失败</Option>
              </Select>
            </div>
          </div>
          <div className={styles.policeroadbody_policeroad_search_button}>
            <Button
              type="primary"
              onClick={() => {
                if (
                  !iSTimeMaxDay(
                    GiveExportParams?.startDate,
                    GiveExportParams?.endDate
                  )
                ) {
                  return
                }
                setTimeout(getExportWorkList, 0)
              }}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                setGiveExportParams({
                  pageNum: 1,
                  pageSize: 10,
                  exportTypes: [1],
                  status: undefined,
                  startDate: HandDefaultTime?.startDate,
                  endDate: HandDefaultTime?.endDate,
                  TotalTiem: HandDefaultTime?.TotalTime,
                })
                setTimeout(getExportWorkList, 0)
              }}
            >
              重置
            </Button>
          </div>

          <Table
            style={{ marginTop: "20px", width: "100%" }}
            dataSource={exportList}
            columns={columns1}
            pagination={onTableChange1()}
            rowKey="id"
            loading={loading1}
            // rowSelection={rowSelection}
            scroll={{ x: "max-content", y: `${WinHeight - 470}px` }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Index
